import { FormControl } from '@angular/forms';
import { DurationModel, LovedOnePassportModel } from '@nx-c4g/c4g-models';
import { notify } from '../notify-drawer/notify';

export interface BookingFormGroup {
  notify?: FormControl<string | null>;
  punctual?: FormControl<string[] | null>;
  location: FormControl<string>;
  lovedOnes: FormControl<LovedOnePassportModel[]>;
  selectedLO: FormControl<LovedOnePassportModel>;
  durationCtrl: FormControl<DurationModel>;
  weeks: FormControl<number>;
  days: FormControl<string[]>;
}
