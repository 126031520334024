export interface AdminSalesModel {
  _id: string;
  monthName : string;
  totalCost : number;
  totalCharge : number;
  totalPaidToCg : number;
  totalC4g : number;
  totalRefund : number;
  totalPenalty : number;
}

export interface AdminRevenueModel {
  _id: string;
  monthName : string;
  total : number;
}
