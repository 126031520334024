import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  AppointmentModel,
  CheckInModel,
  GeneralUtil,
} from '@nx-c4g/c4g-models';
import { notify } from '@nx-c4g/c4g-ui';

import { Plugins } from '@capacitor/core';
import { CheckInsService } from '../../services/check-ins/check-ins.service';
import { AuthenticationService } from '../../services/authentication-service/authentication.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

const { Geolocation } = Plugins;

@Component({
  selector: 'nx-c4g-in-out-appointment',
  templateUrl: './in-out-appointment.component.html',
  styleUrls: ['./in-out-appointment.component.scss'],
})
export class InOutAppointmentComponent implements OnChanges {
  @Input() appointment: AppointmentModel;

  displayedAppointment: AppointmentModel;
  inDisabled = false;
  outDisabled = false;
  init = false;

  constructor(
    private checkInsService: CheckInsService,
    private translate: TranslateService,
    private auth: AuthenticationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appointment && !this.init) {
      this.displayedAppointment = JSON.parse(JSON.stringify(this.appointment));
      this.init = true;
    }
  }

  get name() {
    return GeneralUtil.stripLastName(this.displayedAppointment.lovedOnesPassports[0].LovedOnePersonalInfo.Name);
  }

  async getInOutModel(): Promise<CheckInModel> {
    let coordinates;
    try {
      coordinates = await Geolocation.getCurrentPosition({
        timeout: 6000,
        enableHighAccuracy: true,
        maximumAge: 1,
      });
      if (!coordinates) {
        notify(
          this.translate.instant('location-error'),
          'error'
        );
        alert(this.translate.instant('location-error'));
        return null;
      }
    } catch (error) {
      notify(
        this.translate.instant('location-error'),
        'error'
      );
      alert(this.translate.instant('location-error'));
      return null;
    }

    const latitude = coordinates.coords.latitude;
    const longitude = coordinates.coords.longitude;
    console.log('Appointment', this.appointment);
    const checkInModel: CheckInModel = {
      appointmentId: this.appointment._id,
      careGiverId: this.auth.user.uid,
      careGiverName: this.appointment.caregiverName,
      careSeekerId: this.appointment.careseeker,
      careSeekerName: this.appointment.careseekerName,
      careBookId:
        this.appointment.lovedOnesPassports[0] !== null
          ? this.appointment.careseeker +
            this.appointment.lovedOnesPassports[0].Id
          : null,
      position: {
        Latitude: latitude,
        Longitude: longitude,
      },
    };

    return checkInModel;
  }

  info() {
    // Open google map in a new tab with the location of the appointment
    const apptLocation = { Latitude: 0, Longitude: 0 };
    apptLocation.Latitude =
      this.appointment.lovedOnesPassports[0].LovedOnePersonalInfo.Address.Latitude;
    apptLocation.Longitude =
      this.appointment.lovedOnesPassports[0].LovedOnePersonalInfo.Address.Longitude;
    const url = `https://www.google.com/maps/search/?api=1&query=${apptLocation.Latitude},${apptLocation.Longitude}`;
    window.open(url, '_blank');
  }

  haversine_distance(mk1, mk2) {
    let R = 6371.071; // Radius of the Earth in km
    let rlat1 = mk1.Latitude * (Math.PI / 180); // Convert degrees to radians
    let rlat2 = mk2.Latitude * (Math.PI / 180); // Convert degrees to radians
    let difflat = rlat2 - rlat1; // Radian difference (latitudes)
    let difflon = (mk2.Longitude - mk1.Longitude) * (Math.PI / 180); // Radian difference (longitudes)

    let d =
      2 *
      R *
      Math.asin(
        Math.sqrt(
          Math.sin(difflat / 2) * Math.sin(difflat / 2) +
            Math.cos(rlat1) *
              Math.cos(rlat2) *
              Math.sin(difflon / 2) *
              Math.sin(difflon / 2)
        )
      );
    return d;
  }

  async in() {
    this.inDisabled = true;
    const checkInModel = await this.getInOutModel();
    if (!checkInModel) {
      this.inDisabled = false;
      return;
    }
    const apptLocation = { Latitude: 0, Longitude: 0 };
    apptLocation.Latitude =
      this.appointment.lovedOnesPassports[0].LovedOnePersonalInfo.Address.Latitude;
    apptLocation.Longitude =
      this.appointment.lovedOnesPassports[0].LovedOnePersonalInfo.Address.Longitude;

    const distance = this.haversine_distance(
      apptLocation,
      checkInModel.position
    );
    console.log('DISTANCE', distance);
    if (environment.production && distance > 0.2) {
      notify(
        `You must be at the target to Check-In or Out, you are currently ${distance.toFixed(
          1
        )} km away`,
        'error'
      );
    } else {
      try {
        const checkInOutModel = await this.checkInsService.in(checkInModel);
        this.displayedAppointment.checkInOut = checkInOutModel;
        this.inDisabled = false;
        this.cd.detectChanges();
        notify('Check-In successful', 'info');
      } catch (ex) {
        this.inDisabled = false;
        notify(ex['error'], 'error');
      }
    }
  }

  async out() {
    this.outDisabled = true;

    const checkInModel = await this.getInOutModel();
    if (!checkInModel) {
      this.inDisabled = false;
      return;
    }

    const apptLocation = { Latitude: 0, Longitude: 0 };
    apptLocation.Latitude =
      this.appointment.lovedOnesPassports[0].LovedOnePersonalInfo.Address.Latitude;
    apptLocation.Longitude =
      this.appointment.lovedOnesPassports[0].LovedOnePersonalInfo.Address.Longitude;

    const distance = this.haversine_distance(
      apptLocation,
      checkInModel.position
    );
    console.log('DISTANCE', distance);
    if (environment.production && distance > 0.2) {
      notify(
        `You must be at the target to Check-In or Out, you are currently ${distance.toFixed(
          1
        )} km away`,
        'error'
      );
    } else {
      try {
        const checkInOutModel = await this.checkInsService.out(checkInModel);
        this.displayedAppointment.checkInOut = checkInOutModel;
        this.cd.detectChanges();
        this.outDisabled = false;
        notify('Check-Out successful', 'info');
      } catch (ex) {
        this.outDisabled = false;
        notify(ex['error'], 'error');
      }
    }
  }
}
