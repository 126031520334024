export interface GoogleAddressModel {
  streetNumber: number;
  route: string;
  locality: string;
  administrativeAreaLevel1: string;
  administrativeAreaLevel1Short: string;
  country: string;
  countryCode: string;
  postalCode: string;
  longitude: number;
  latitude: number;
}
