import {C4gPaymentTypes} from "../enums/C4gPaymentTypes";
import {AmountTx} from "./AmountTx";

export interface C4gPaymentModel {
  _id: string;
  csId: string;
  cgId: string;
  appointmentId?: string;
  date: Date;
  type: C4gPaymentTypes;
  payment: AmountTx;
}

