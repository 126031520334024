import {CsOperationTypes} from "../enums/CsOperationTypes";
import {AmountTx} from "./AmountTx";
import {CsBankModel} from "./Bank";
import {CsOperationGroupTypes} from "../enums/CsOperationGroupTypes";

export interface CsOperationsModel {
  _id: string;
  csId: string;
  date: Date;
  type: CsOperationTypes;
  group: CsOperationGroupTypes;
  amount: AmountTx;
  updatedBank: CsBankModel;
  reference: any;
}

