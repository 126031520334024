
import {Type} from "class-transformer";
import {AppointmentModel} from "./appointmentModel";
import { TaskModel } from "./taskModel";

export class CareBookModel {
  _id: string;
  name: string;
  isSubsidized: boolean;
  @Type(() => AppointmentModel)
  // appointments: AppointmentModel[];
  care: TaskModel;
}
