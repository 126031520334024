import { GeoLocationPositionModel } from './geoLocation';

export interface SearchModel {
  passport: any;
  location: GeoLocationPositionModel;
  maxPrice: number;
  name: string;
  maxDistance: number;
  languages: string[];
  certifications: string[];
  services: string[];
  customLocation: boolean;
  availabilities: string[];
}
