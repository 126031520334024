import {AmountTx} from "./AmountTx";

export interface PenaltyModel {
  _id: string;
  cs: string;
  cg: string;
  percent: number;
  cost: AmountTx;
  charges: AmountTx;
  c4g: AmountTx;
}

export interface PenaltyContentModel {
  cost: AmountTx;
  charges: AmountTx;
  c4g: AmountTx;
}
