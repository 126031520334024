
export interface LoRates {
  _id: string;
  csId: string;
  csName: string;
  csEmail: string;
  loId: string;
  loName: string;
  rate: number;
  c4gMargin?: number
  calculatedRate?: number;
  subsidized?: boolean;
  subsidizedRate?: number;
  subsidizedHoursPerPeriod?: number;
  subsidizedC4gMargin?: number;
  subsidizedStartDate?: Date;
  subsidizedResetPeriod?: number;
}

export interface LoRatesEntry {
  _id: string;
  periods?: LoRatePeriod[];
}

export interface LoRatePeriod {
  from: Date;
  to: Date;
  hoursLeft: number;
  transactions: LoRateTransaction[];
}

export interface LoRateTransaction {
  date: Date;
  hours: number;
  bookingId: string;
  apptId: string;
}
